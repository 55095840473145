<template>
  <div>
    <v-autocomplete
      :disabled="disabled === true"
      :search-input.sync="search"
      :items="user_list"
      clearable
      :loading="loading"
      @change="onChange"
      class="pa-0"
      label="Start typing to search (Mobile/Email/Name)"
      item-text="name"
      item-value="id"
      v-model="enroll"
      outlined
      dense
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-avatar>
            <img :src="data.item.avatar || logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.sub_title"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import cookie from "js-cookie";

let typingTimer;
let doneTypingInterval = 400;

export default {
  props: ["companyId", "disabled", "withDeleted"],
  data() {
    return {
      enroll: "",
      loading: false,
      user_list: [],
      search: "",
    };
  },
  watch: {
    search(val) {
      if (!val) return;

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.searchUserApi(val);
      }, doneTypingInterval);
    },
  },
  computed: {
    logo() {
      return cookie.get("_cl") || "https://image.ibb.co/cVTEsG/eAcademy.png";
    },
  },
  mounted() {
    if (this.companyId && this.$auth.getRole() !== "super") {
      throw Error("Invalid user of search student");
    }
  },
  methods: {
    searchUserApi(val) {
      if (!val) return;
      this.isLoading = true;
      let endpoint =
        "api/user?rowsPerPage=10&page=1&sortBy=name&rowsPerPage=10&slim=true&search=" +
        val.trim();
      if (this.$auth.getRole() === "super" && this.companyId) {
        endpoint += "&super_mode_company_id=" + this.companyId;
      }
      if (this.withDeleted === true) {
        endpoint += "&with_deleted=true";
      }
      this.$rest
        .get(endpoint)
        .then(({ data }) => {
          this.user_list = data.data.map(function(item) {
            let primary_role = null;
            item.roles.map((role) => {
              if (role.is_primary) {
                primary_role = role;
              }
            });
            let subtitle = "";
            if (item.primary_mobile) {
              subtitle += item.primary_mobile;
            }
            if (item.email) {
              subtitle += " / " + item.email;
            }
            if (primary_role) {
              subtitle += " / " + primary_role.name;
            }
            return {
              name: item.full_name,
              email: item.email,
              primary_mobile: item.primary_mobile,
              id: item.id,
              avatar: item.profile_image,
              sub_title: subtitle,
              primary_role,
            };
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChange(id) {
      let user = null;
      this.user_list.map((item) => {
        if (item.id === parseInt(id)) {
          user = item;
        }
      });
      this.$emit("onSelectUser", user);
    },
  },
};
</script>
<style lang="scss">
.v-text-field__details {
  display: none;
}
</style>
