<template>
    <div>
        <v-select :loading="batchLoading"
                  outlined dense
                  @change="setBatch"
                  v-bind:value="value"
                  :items="batches"
                  class="pa-0"
                  :label="label || 'Select batch'"/>
    </div>
</template>
<script>

    export default {
        props: ['value', 'label', 'companyId', 'onlyPrevious', 'onlyCurrent'],
        data() {
            return {
                batchLoading: false,
                batches: [],
            }
        },
        watch: {},
        computed: {},
        mounted() {
            this.getBatches(this.companyId);
        },
        methods: {
            setBatch(value) {
                this.$emit('input', value);
            },
            getBatches(companyId) {
                if (!this.batches.length) {
                    let url = '/api/batch?rowsPerPage=25&descending=true&slim=true';
                    if (companyId)
                        url = '/api/super/batch?rowsPerPage=25&descending=true&slim=true&companyId=' + companyId;

                    this.$rest.get(url).then(({data}) => {

                        const batchList = [];
                        data.data.map(item => {
                            if (this.onlyPrevious === true) {
                                if (!item.is_current_batch) {
                                    batchList.push({value: item.id, text: item.name});
                                }
                            } else if (this.onlyCurrent === true) {
                                if (item.is_current_batch) {
                                    batchList.push({value: item.id, text: item.name});
                                }
                            } else {
                                batchList.push({value: item.id, text: item.name});
                            }
                        });
                        this.batches = batchList;
                    }).finally(() => {
                        this.batchLoading = false;
                    })
                }
            },
        }
    }
</script>
<style lang="scss">

</style>