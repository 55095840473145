/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/conference/_components/${view}.vue`)
}

let routes = [
    {
        name: 'live-class',
        path: 'live-class',
        component: loadView('index'),
        meta: {
            auth: true, 
        }
    }, {
        name: 'live-class-detail',
        path: '/live-class/:classId',
        component: loadView('video'),
        meta: {
            auth: true,
        }
    },
    {
        name: 'zoom',
        path: '/zoom-int/:meetingId',
        component: loadView('zoom'),
        meta: {
            auth: true,
        }
    },
];

export default routes;