import dashboardRoutes from './dashboard/_routes'
import departmentRoutes from './department/_routes'
import guardianModuleRoute from './guardianModule/_routes'
import schoolGuardianRoutes from './schoolGuardianAssign/_routes'
import transitRoutes from './transit/_routes'
import academicRoutes from './academic/_routes'
import studentRoutes from './students/_routes'
import examRoutes from './exam/_routes'
import billingRoutes from './billing/_routes'
import accountRoutes from './account/_routes'
import onlineRegistrationRoutes from './onlineRegistration/_routes'

import miscellaneous from './miscellaneous/_routes'
import transportation from './transportation/_routes'
import cardRoutes from './idcard/_routes'
import activityReportRoutes from './activityReport/_routes'
import aio from './aio/_routes'
import sms from './sms/_routes'
import calendar from './calendar/_routes'
import conferenceRoute from './conference/_routes'
import studyMaterials from './learningMaterials/_routes'
// import fees from './studentsAccounting/_routes'

// import auth from "../library/Auth";

// let menus = [];
// let SMSmenus = [];
// if (auth.loggedInRole() && auth.loggedInRole().slug === 'administrator') {
//     SMSmenus = [...sms]
// }
//
// if(auth.loggedInRole().slug === 'guardian') {
//     menus = [...dashboardRoutes,...guardianModuleRoute,...calendar,];
// }else {
//     menus = [...dashboardRoutes, ...aio, ...schoolGuardianRoutes, ...transitRoutes, ...academicRoutes, ...studentRoutes, ...examRoutes, ...transportation, ...billingRoutes, ...accountRoutes, ...calendar, ...miscellaneous, ...cardRoutes, ...activityReportRoutes, ...onlineRegistrationRoutes,...guardianModuleRoute].concat(SMSmenus);
// }

//
// <<<<<<< HEAD
// let routes = [...dashboardRoutes, ...departmentRoutes, ...aio, ...schoolGuardianRoutes, ...transitRoutes, ...academicRoutes, ...studentRoutes, ...examRoutes, ...transportation, ...billingRoutes, ...accountRoutes, ...calendar, ...miscellaneous, ...cardRoutes, ...activityReportRoutes, ...sms, ...onlineRegistrationRoutes, ...guardianModuleRoute, ...conferenceRoute];
// =======
let routes = [...dashboardRoutes, ...departmentRoutes, ...aio, ...schoolGuardianRoutes, ...transitRoutes, ...academicRoutes, ...studentRoutes, ...examRoutes, ...transportation, ...billingRoutes, ...accountRoutes, ...calendar, ...miscellaneous, ...cardRoutes, ...activityReportRoutes, ...sms, ...onlineRegistrationRoutes, ...guardianModuleRoute, ...conferenceRoute, ...studyMaterials];

export default routes;