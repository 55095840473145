/* eslint-disable */
import Vue from 'vue'
import VueEvents from 'vue-events'
import VueApexCharts from 'vue-apexcharts'
import wysiwyg from "vue-wysiwyg";
import VueTheMask from 'vue-the-mask'
// import IdleVue from 'idle-vue'
import cookie from 'js-cookie'
import vuetify from '@/plugins/vuetify'

import 'vuetify/dist/vuetify.min.css'
import "vue-wysiwyg/dist/vueWysiwyg.css";

Vue.use(VueApexCharts);
Vue.use(VueEvents);
Vue.use(wysiwyg, {
    hideModules: { "image": true },
    maxHeight: "500px",


}); // config is optional. more below
Vue.use(VueTheMask);

require('./library/Calendar');

// import slim from 'jquery-slim';

// global.jQuery = slim;

/** import dependencies **/
import router from './router'
import { store } from './store'

// const eventsHub = new Vue();
//60000 is 1 minute
// Vue.use(IdleVue, {eventEmitter: eventsHub, idleTime: 600000, store});

/** import library **/
import rest from '@/library/Rest'
/** Rest (Axios) API */
import storage from '@/library/Storage'
/** Web Storage API */
import auth from '@/library/Auth'
/** Authentication API */
import encryption from '@/library/Encryption'
/** AddressHelper API */
import AddressHelper from "./library/AddressHelper";
/** AddressHelper API */
import Permissions from "./library/Permissions";
/**
 * Nav Factory Class responsible to Show or Hide Nav Menu Items with bulk functions.
 */
import NavFactory from "./library/NavFactory";

/** Authentication API */


Vue.prototype.$rest = rest();
Vue.prototype.$storage = storage;
Vue.prototype.$auth = auth;
Vue.prototype.$encryption = encryption;
// Vue.prototype.$slim = slim;
Vue.prototype.$address = new AddressHelper();
Vue.prototype.$permissions = new Permissions();
Vue.prototype.$navFactory = new NavFactory();

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

Number.prototype.pad = function () {
    return this.toString().padStart(2, "0");
};

/**
 * Format to given currency
 * @param  {String} format       Currency Format.
 * @param  {Number} decimalCount Total no of decimal points.
 * @param  {String} decimal      Decimal symbol.
 * @param  {String} thousands    Thousand separator.
 * @return {[type]}              String
 */
Number.prototype.currency = function (format = "Rs.", decimalCount = 2, decimal = ".", thousands = ",") {
    let amount = this;
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return format + " " + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        return 0;
    }
};


Number.prototype.numberFormat = function (format = "", decimalCount = 2, decimal = ".", thousands = ",") {
    let amount = this;
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return format + " " + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        return 0;
    }
};
String.prototype.dateForm = function () {
    if (this.length === 8) return this.substr(0, 4) + '-' + this[4] + this[5] + '-' + this.slice(-2);
    else return this;
};


import App from './App.vue'
import Button from '@/components/Button'
import AddButton from '@/components/AddButton'
import SearchButton from '@/components/SearchButton'
import EditButton from '@/components/EditButton'
import DeleteButton from '@/components/DeleteButton'
import ViewButton from '@/components/ViewButton'
import LoadButton from '@/components/LoadButton'
import RefresButton from '@/components/RefreshButton'
import AlertMessage from '@/components/AlertMessage'
import GradeSection from '@/components/app/GradeSectionFilter'
import PrintButtons from '@/components/PrintButtons'
import VCalendarField from '@/components/VCalendarField'
import NotFound from "./components/svg/NotFound";
import VSubjectField from "./components/app/VSubjectField";
import VGradeField from "./components/app/VGradeField";
import VStudentSearchField from "./components/app/VStudentSearchField";
import VUserSearchField from "./components/app/VUserSearchField";
import VBatchField from "./components/app/VBatchField";
import VSectionField from "./components/app/VSectionField";
import DownloadButton from "./components/DownloadButton";
import VUploadedShowcase from "./components/app/VUploadedShowcase";
import VMultiSelectField from "./components/app/VMultiSelectField";
import VSmsInputField from "./components/app/VSmsInputField";
import ViewMoreOrLess from "./components/ViewMoreOrLess";
import VStudentSemiProfile from "./components/app/VStudentSemiProfile";
import IconHeaderSubHeader from "./components/app/IconHeaderSubHeader";
import VImagePreview from "./components/app/VImagePreview";
import VDownloadColumnSelect from "./components/app/VDownloadColumnSelect";
import StrikerComponent from "./components/StrikerComponent";
// import DatePicker from "./components/NepaliDatePicker/DatePicker";

/** Charts */
import ImagePicker from './components/ImagePicker';
import AttendanceChart from './components/AttendanceChart';


Vue.component('apexchart', VueApexCharts);
Vue.component('StrikerComponent', StrikerComponent);
Vue.component('Button', Button);
Vue.component('AddButton', AddButton);
Vue.component('DownloadButton', DownloadButton);
Vue.component('LoadButton', LoadButton);
Vue.component('SearchButton', SearchButton);
Vue.component('EditButton', EditButton);
Vue.component('DeleteButton', DeleteButton);
Vue.component('AlertMessage', AlertMessage);
Vue.component('ViewButton', ViewButton);
Vue.component('RefreshButton', RefresButton);
Vue.component('GradeSection', GradeSection);
Vue.component('VGradeSectionField', GradeSection);
Vue.component('PrintButtons', PrintButtons);
Vue.component('NotFound', NotFound);
Vue.component('VCalendarField', VCalendarField);
Vue.component('VSubjectField', VSubjectField);
Vue.component('VGradeField', VGradeField);
Vue.component('VSectionField', VSectionField);
Vue.component('VBatchField', VBatchField);
Vue.component('VUploadedShowcase', VUploadedShowcase);
Vue.component('VMultiSelectField', VMultiSelectField);
Vue.component('ViewMoreOrLess', ViewMoreOrLess);
Vue.component('VStudentSearchField', VStudentSearchField);
Vue.component('VStudentSemiProfile', VStudentSemiProfile);
Vue.component('IconHeaderSubHeader', IconHeaderSubHeader);
Vue.component('VImagePreview', VImagePreview);
Vue.component('VDownloadColumnSelect', VDownloadColumnSelect);
Vue.component('VUserSearchField', VUserSearchField);
Vue.component('VSmsInputField', VSmsInputField);

Vue.component('ImagePicker', ImagePicker);
Vue.component('AttendanceChart', AttendanceChart);

// Vue.component('ErrorBoundary', {
//     data: () => ({ error: null }),
//     errorCaptured (err, vm, info) {
//         console.log(err.stack);
//         this.error = `${err.stack}\n\nfound in ${info} of component`;
//         return false
//     },
//     render (h) {
//         if (this.error) {
//             return h('pre', { style: { color: 'red' }}, this.error)
//         }
//         // ignoring edge cases for the sake of demonstration
//         return this.$slots.default[0]
//     }
// });

/** Filters... */
Vue.filter('capitalize', (value) => {
    if (!value) return '';
    return value.toString().charAt(0).toUpperCase() + value.slice(1)
});

Element.prototype.documentOffsetTop = function () {
    return this.offsetTop + (this.offsetParent ? this.offsetParent.documentOffsetTop() : 0);
};

/**
 *
 * @param arr
 * @returns {*[]}
 */
Array.prototype.diff = function (arr, key) {
    return this.filter(function (i) {
        return !(arr.includes(i[key]))
    });
};


String.prototype.cleanHtml = function (length = null) {
    var doc = new DOMParser().parseFromString(this, 'text/html');
    var str = doc.body.textContent || "";
    if (length) {
        return str.length > length ? str.substr(0, length) + '...' : str;
    }
    return str;
};


Vue.config.productionTip = false;
window.app = new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    onIdle() {
        // Logout if user is not active
        if (auth.authenticated()) {
            storage.clear();
            cookie.remove('_user');
            cookie.remove('_permissions');
            router.replace({ name: 'login' });
        }
    },
    onActive() {
    }
}).$mount('#app');