<template>
  <div>
    <div class="mb-5">
      <v-chip
        @click="attachPlaceholder(item)"
        :key="key"
        v-for="(item, key) in placeholders"
        class="mr-2"
        style="cursor:pointer;"
        x-small
        label
        >{{ item.text }}</v-chip
      >
    </div>
    <v-textarea
      style="font-weight:bold"
      spellcheck="false"
      :value="sms"
      class="mb-0"
      :label="
        `Input your SMS (${sms.length}) / ${
          sms_stats && sms_stats.msg_type === 2 ? 'UNICODE' : 'ASCII'
        }`
      "
      @keyup="typeSMS"
      outlined
    ></v-textarea>

    <div v-if="my_sms" class="data-represent" style="margin-left:0">
      <div v-if="hideBalance === false">
        <span class="d-info"></span>&nbsp;
        <strong>Rs.{{ my_sms.balance }}</strong>
        <small> Balance</small> &nbsp; /
        <strong>Rs.{{ my_sms.rate_per_sms }}</strong>
        <small> Unit Price</small>
      </div>
      <div>
        <span class="d-success"></span> &nbsp;
        <strong>{{ sms_stats ? sms_stats.maxCharCount : "" }}</strong>
        <small> MAX Char</small>
      </div>
      <div>
        <span class="d-warning"></span> &nbsp;
        <strong>{{ sms_stats ? sms_stats.remaining : "" }}</strong>
        <small> Char More</small>
      </div>
      <div>
        <span class="d-primary"></span> &nbsp;
        <strong>{{ sms_stats ? sms_stats.numberOfSMS : "" }}</strong>
        <small> SMS</small>
      </div>
      <div>
        <span class="d-error"></span> &nbsp;
        <strong v-if="sms_stats">
          RS.{{
            (
              my_sms.rate_per_sms *
              sms_stats.numberOfSMS *
              totalSms
            ).numberFormat()
          }}
        </strong>
        <small> Estimated Cost</small>
      </div>
    </div>
    <br />
    <v-divider></v-divider>
    <br />
    <p v-if="showAlert" style="display:flex">
      <v-icon color="#ff5252" size="30" class="mr-2">error</v-icon>
      <span>
        Please Note!! When using dynamic placeholders please take the above
        statistics for general idea only and not for exact calculation. If you
        want to get the exact calculation please
        <a href=""><u>Click Here</u></a>
      </span>
    </p>
  </div>
</template>
<script>
import SMSCalculator from "@/library/SMS";
const smsCalculator = new SMSCalculator();

export default {
  props: {
    defaultSms: {
      type: String,
      default: null,
    },
    hideBalance: {
      type: Boolean,
      default: false,
    },
    defaultPlaceholder: {
      default: null,
    },
    balanceStats: {
      default: null,
    },
    totalSms: {
      default: 1,
    },
    showAlert: {
      default: true,
    },
    value: { type: String },
  },
  data: () => ({
    sms: "",
    sms_stats: null,
    placeholders: [
      {
        text: "Guardian Name",
        placeholder: "{{guardian_name}}",
      },
      {
        text: "Student Name",
        placeholder: "{{student_name}}",
      },
      {
        text: "Grade",
        placeholder: "{{grade}}",
      },
      {
        text: "Section",
        placeholder: "{{section}}",
      },
      {
        text: "Enroll Code",
        placeholder: "{{enroll}}",
      },
      {
        text: "Batch",
        placeholder: "{{batch}}",
      },
      {
        text: "Guardian Email",
        placeholder: "{{guardian_email}}",
      },
      {
        text: "Guardian Mobile",
        placeholder: "{{guardian_mobile}}",
      },
      {
        text: "School",
        placeholder: "{{school_name}}",
      },
      {
        text: "Due Amount",
        placeholder: "{{due_amout}}",
      },
      {
        text: "GPA",
        placeholder: "{{gpa_score}}",
      },
    ],
    my_sms: null,
  }),
  computed: {},
  watch: {
    sms: function(v) {
      if (!v) return;
      const smsStats = smsCalculator.getCount(this.sms);
      this.sms_stats = smsStats;
      this.$emit("message", {
        message: this.sms,
        stats: this.sms_stats,
        my_sms: this.my_sms,
      });
    },
    value: function(v) {
      this.sms = v;
    },
  },
  mounted() {
    if (this.defaultSms) {
      this.sms = this.defaultSms;
    }
    if (this.defaultPlaceholder !== null) {
      this.placeholders = this.defaultPlaceholder;
    }
    if (!this.balanceStats) {
      this.my_sms = null;
      this.$rest
        .get("/api/sms/dashboard")
        .then(({ data }) => {
          this.my_sms = data;
          this.$emit("message", {
            message: this.sms,
            stats: this.sms_stats,
            my_sms: this.my_sms,
          });
        })
        .finally(() => {});
    } else {
      // {balance:100,rate_per_sms:1.6} balanceStats must contain these properties
      this.my_sms = this.balanceStats;
    }
  },
  methods: {
    typeSMS(e) {
      this.sms = e.target.value;
      this.$emit("input", this.sms);
    },
    attachPlaceholder(item) {
      if (item && item.placeholder) {
        this.sms = this.sms.trim();

        this.sms += " " + item.placeholder;
        this.sms = this.sms.trim();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uploaded-file-preview {
  /*display: flex;*/
  /*min-height: 40px;*/
  width: 100%;
  /*border-radius: 15px;*/
  /*justify-content: center;*/
  align-items: center;

  overflow: hidden;
  margin-bottom: 15px;
  .icon-holder {
    width: 20%;
    padding: 5px 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    float: left;
    flex: 1;
    /*height: 40px;*/
    background-color: #1976d2 !important;
    border: 1px solid #1976d2;

    text-align: center;
  }
  .file-info-holder {
    border: 1px solid #ccc;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    border-left: none;
    padding: 5px 15px;
    color: #777;
    width: 80%;
    float: right;
    position: relative;
    p {
      margin-bottom: 0;
      line-height: 1;
    }
    i,
    button {
      position: absolute;
      right: 5px;
      font-size: 18px;
      color: #666;
      top: 5px;
      cursor: pointer;
    }
  }
}
</style>
