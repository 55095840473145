import Rest from '@/library/Rest'
import cookie from 'js-cookie';
import auth from '@/library/Auth';

export const getPendingPayment = ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
        //reset state
        state.items = [];
        state.pendingInvoice = {};
        state.fetching = true;
        state.totalPaymentAmount = 0;
        state.paymentAlertModel = false;

        if (!['accountant', 'administrator'].includes(auth.getRole())) return;

        let endpoint = '/api/payment-alert-status';

        const remind_later_flag = cookie.get('_al_ch_for');
        if (remind_later_flag) {
            endpoint += '?reminder_later_flag=' + remind_later_flag;
        }

        Rest().get(endpoint).then(({ data }) => {
            if (!Object.keys(data).length) return;

            if (data.show_alert === true)
                state.paymentAlertModel = true;

            state.pendingInvoice = data;
            state.pendingInvoice.payment_heads.map((item) => {
                state.totalPaymentAmount += item.amount;
            });

            if (data.action_type === 'success' && data.is_expired === false) {
                state.alertMessage = `Your subscription to Vidhyalaya is expiring at ${data.payment_date} i.e ${data.human}. Please renew in time. Thank you!!`;
            } else if (data.action_type === 'warning' && data.is_expired === false) {
                state.alertMessage = `Your subscription to Vidhyalaya is expiring at ${data.payment_date} i.e ${data.human}. Please renew in time. Thank you!!`;
            } else if (data.is_expired === false) {
                state.alertMessage = `CRITICAL ALERT!! Your subscription to Vidhyalaya is expiring today (${data.payment_date} i.e ${data.human}). Please renew. Thank you!!`;
            } else if (data.is_expired === true) {
                state.alertMessage = `CRITICAL ALERT!! Your subscription to Vidhyalaya has been expired. (${data.payment_date} i.e ${data.human}). Please renew. Thank you!!`;
            }

            resolve(data)
        }).catch(err => {
            reject(err);
        }).finally(() => {
            state.fetching = false;
        });
    });
};

export const updateReminder = ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {

        if (!['accountant', 'administrator'].includes(auth.getRole())) return;

        let endpoint = '/api/payment-alert-later/' + payload.id;

        Rest().put(endpoint, payload).then(({ data }) => {
            state.paymentAlertModel = false;
            resolve(data)
        }).catch(err => {
            reject(err);
        }).finally(() => {
            state.fetching = false;
        });
    });
};
