<template>

    <v-select :disabled="!sectionItems.length" :loading="grade.section.fetching"
              :items="sectionItems"
              class="pa-0"
              @change="setSection"
              outlined dense
              label="Select section"/>

</template>
<script>
    import {mapState, mapMutations} from 'vuex'

    export default {
        props: ['gradeId', 'value', 'sections'],
        data() {
            return {}
        },
        watch: {
            'gradeId': function (value) {
                if (value) {
                    let $this = this;
                    if (!this.sections)
                        this.grade.grade.items.map((item) => {
                            if ($this.grade.grade.selected === item.id) {
                                $this.SET_SECTIONS(item.sections);
                            }
                        });
                }
            }
        },
        computed: {
            ...mapState(['grade']),
            sectionItems() {
                const sections = this.sections || this.grade.section.items;
                return sections.map((item) => {
                    return {value: item.id, text: item.name}
                });
            }
        },
        methods: {
            ...mapMutations(['SET_SECTIONS']),

            setSection(value) {
                if (!this.sections) {
                    this.grade.section.selected = value;
                }
                this.$emit('input', value);
                this.$emit('onSectionChange', value);
            }
        }
    }
</script>
<style lang="scss">

</style>