<template>
    <v-btn class="mr-2" v-if="hasPermission && showButton" :disabled="disabled" outlined small :color="color || 'primary'"
           @click="$emit('action')">
        <v-icon left small>{{ icon || 'add_circle' }}</v-icon>
        <slot></slot>
    </v-btn>
</template>
<script>

    import {isCurrentBatch} from "../library/helpers";

    export default {
        props: {
            'icon': {required: false},
            'permission': {required: false},
            'color': {required: false},
            'disabled': {required: false, default: false},
            'onCurrentBatch': {required: false, default: true}
        },
        data: () => ({
            hasPermission: false
        }),
        computed: {
            showButton() {
                if (this.onCurrentBatch === true) {
                    return this.isCurrentBatch();
                }
                return true;
            }
        },
        mounted() {
            if (this.permission === true) {
                this.hasPermission = true;
                return true;
            }

            if (this.permission)
                this.hasPermission = this.$auth.can(this.permission);

        },
        methods: {
            isCurrentBatch
        }
    }
</script>
<style lang="scss">
    .eac-add-button {
        .v-chip__content {
            cursor: pointer;
        }
    }
</style>