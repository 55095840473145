/** Lazy Load View **/
function loadView(view) {
	return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/department/_components/${view}.vue`)
}

let routes = [
	{
		name: 'department',
		path: '/department',
		component: loadView('Department/index'),
		meta: {
			auth: true,
			permission: "staff-create"
		}
	},
	{
		name: 'department-detail',
		path: '/department/:departmentId',
		component: loadView('Department/detail'),
		meta: {
			auth: true,
			permission: 'section-read'
		}
	}
];

export default routes;