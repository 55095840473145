var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.student).length && _vm.showInfo)?_c('div',{staticClass:"student-semi-profile-container"},[_c('div',{staticClass:"brief_info"},[_c('div',{staticClass:"std_img"},[_c('img',{attrs:{"src":_vm.logo,"alt":""}})]),_c('div',{staticClass:"std_info"},[_c('div',[_c('h3',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._v(_vm._s(_vm.student.name)+" ")]),_c('p',[_vm._v(_vm._s(_vm.student.address))])]),(_vm.student.details)?_c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticClass:"info_std_item"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("more")]),_vm._v(" Admission "+_vm._s(_vm.student.details.admission_date)+" ")],1),_c('div',{staticClass:"info_std_item"},[_c('v-icon',{attrs:{"color":"#03BEFF"}},[_vm._v("home")]),_vm._v(" "+_vm._s(_vm.student.details.grade)+" / "+_vm._s(_vm.student.details.section)+" ")],1),_c('div',{staticClass:"info_std_item"},[_c('v-icon',{attrs:{"color":"#F96BE6"}},[_vm._v("fingerprint")]),_vm._v(" "+_vm._s(_vm.student.details.enroll_code)+" ")],1),_c('div',{staticClass:"info_std_item"},[_c('v-icon',{attrs:{"color":"#FFD202"}},[_vm._v("military_tech")]),_vm._v(" Roll "+_vm._s(_vm.student.details.roll)+" ")],1)]):_vm._e()]),_c('div',{staticClass:"std_action"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":'/student/'+_vm.student.id}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("account_circle")])],1)]}}],null,false,275655095)},[_vm._v(" "+_vm._s(_vm.student.id)+" "),_c('span',[_vm._v("Go to Profile")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.showInfo=false}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("cancel")])],1)]}}],null,false,2090433705)},[_c('span',[_vm._v("Hide User Info")])])],1)]),_c('div',{staticClass:"exam_information"},[_c('label',[_vm._v("Guardian ")]),_c('div',{staticClass:"extra_info_info"},[_c('p',[_vm._v(_vm._s(_vm.student.guardian.name)+" ("+_vm._s(_vm.student.guardian.relation)+"), "),_c('v-icon',{staticStyle:{"margin-left":"10px"},attrs:{"size":"16","color":"primary"}},[_vm._v("phone")]),_vm._v(" "+_vm._s(_vm.student.guardian.mobile || 'N/A')+" ")],1)])]),_c('div',{staticClass:"extra_actions"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("more_horiz ")])]}}],null,false,779289747)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }