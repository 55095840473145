<template>
  <v-dialog v-if="hasPermission" v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on }">
      <v-btn
        x-small
        :outlined="outlined === undefined ? true : outlined"
        color="error"
        class="ml-1 mt-1 mb-1"
        v-on="on"
      >
        <v-icon x-small>{{ icon || "clear" }}</v-icon
        ><strong class="ml-1">Delete</strong>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="title pa-3 red white--text">
        <v-icon class="mr-2 white--text">warning</v-icon>
        Please, wait!
      </v-card-title>
      <v-card-text class="ma-0">
        <!-- <h3>{{message||'Are you sure you want to delete this item?'}}</h3>
                <v-divider></v-divider> -->

        <p class="ml-0 mr-0 mb-0 pb-0 mt-3">
          {{ description }} Please, make sure you are fully aware what you are
          doing. You can always contact us if you are confused or need any help.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          small
          @click="(dialog = false), $emit('disagree')"
          >Cancel</v-btn
        >
        <v-btn
          color="error"
          text
          small
          @click="(dialog = false), $emit('agree')"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["icon", "permission", "message", "description", "outlined"],
  data: () => ({
    dialog: false,
    hasPermission: false,
  }),
  mounted() {
    if (this.permission)
      // this.hasPermission = this.$permissions.checkPermissions(this.permission);
      this.hasPermission = this.$auth.can(this.permission);

    // if (this.permission) {
    //     const requiredPermissions = this.permission.split('|');
    //     this.hasPermission = this.$permissions.hasPermission(requiredPermissions);
    // }
  },
};
</script>
