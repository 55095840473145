/** Lazy Load View **/
function loadView(view) {
	return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/guardianModule/_components/${view}.vue`)
}

let routes = [
	{
		path: 'guardian-assignment',
		name: 'guardian-assignment',
		component: loadView('assignment/index'),
		meta: {
			auth: true
		}
	},{
		path: 'guardian-result',
		name: 'guardian-result',
		component: loadView('result/index'),
		meta: {
			auth: true
		}
	},{
		path: 'guardian-class-routine',
		name: 'guardian-class-routine',
		component: loadView('classRoutine/index'),
		meta: {
			auth: true
		}
	},{
		path: 'guardian-exam-routine',
		name: 'guardian-exam-routine',
		component: loadView('examRoutine/index'),
		meta: {
			auth: true
		}
	}
];

export default routes;