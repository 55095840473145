export default {
    sidebarMV: false,
    user: {},
    batch: {},
    fiscalyear: { id: 1 },
    student: {
        updateGeneral: false,
        updateGuardian: false,
        deleteAlert: false,
        guardian: [],
        selectedGuardian: {
            family: {}
        },
        data: {},
        updateData: {
            fname: '',
            mname: '',
            lname: '',
            gender: '',
            email: '',
            dob: '',
            blood_group: '',
            religion: '',
            ethnicity: '',
            residence: ''
        },
        addressData: {
            temporary: {},
            permanent: {
                district_id: ''
            }
        }
    },
    batches: [],
    selected_student: '',
}