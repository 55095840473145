function loadView(view) {
    return () => import(`@/modules/calendar/_components/${view}.vue`)
}

let routes = [
    {
        name: 'calendar',
        path: '/calendar',
        component: loadView('Calendar/index'),
        meta: {
            auth: true,
            permission: 'calendar-read'
        }
    }
];

export default routes;