<template>
  <div>
    <v-text-field
      @blur="inputBlurred"
      @focus="inputBlurred"
      @keyup="typeWasDone"
      :rules="dateValidation"
      v-bind:value="date"
      :placeholder="placeholder || ''"
      outlined
      dense
      :v-mask="'####-##-##'"
      :label="label"
      :prepend-inner-icon="prependIcon"
      autocomplete="off"
      required
      class="pa-0 calendar_picker_input"
      name="calendar_picker_input"
      :disabled="disabled"
      :id="idVal"
    />
  </div>
</template>

<script>
import { validateDate } from "../library/helpers";
const NepaliDate = require("nepali-date");
const d4 = new NepaliDate(new Date());

export default {
  props: {
    value: { type: String },
    label: { type: String, required: true },
    disablePreviousDate: { type: Boolean, default: false },
    idVal: { type: String },
    placeholder: { type: String },
    prependIcon: { type: String },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    date: "",
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
  }),
  watch: {},
  mounted() {
    this.date = this.value;
    let $this = this;

    try {
      const today = d4.format("MM/DD/YYYY");
      const dateObject = {
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
        npdMonth: true,
        npdYear: true,
        onChange: function() {
          $this.date = $("#" + $this.idVal).val();
          $this.$emit("input", $this.date);
          $this.$emit("onChange", $this.date);
        },
      };
      if (this.disablePreviousDate) {
        dateObject.disableBefore = today;
      }
      jQuery("#" + this.idVal).nepaliDatePicker(dateObject);
    } catch (e) {}
  },
  methods: {
    typeWasDone(e) {
      e.target.value = "";
      this.date = "";
      this.$emit("input", "");
    },
    inputBlurred(e) {
      if (!validateDate(e.target.value)) {
        e.target.value = "";
        this.date = "";
        this.$emit("input", "");
      }
    },
  },
  beforeDestroy() {},
};
</script>
