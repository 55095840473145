<template>
    <v-dialog v-if="hasPermission" v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on }">
            <v-btn :loading="loading || false" :disabled="disabled || false"
                   :x-small="xSmall || true"
                   :large="large || false"
                   :block="block || false"
                   :outlined="outlined===undefined? true:outlined" :color="color || 'warning'"
                   class="ml-1 mb-1 mt-1"
                   v-on="on">
                <v-icon x-small>{{ icon || 'edit'}}</v-icon>
                <strong class="ml-1">{{label||'Edit'}}</strong>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="title pa-3 warning white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Please, wait!
            </v-card-title>
            <v-card-text>
                <br>
                <p class="ml-0 mr-0 mb-0 pb-0 mt-3">{{message || 'Are you sure you want to edit this item?'}}</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text small @click="dialog = false, $emit('disagree')">Cancel</v-btn>
                <v-btn :loading="loading" color="warning" text small @click="dialog = false, $emit('agree')">{{editText||'Edit'}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: ['icon', 'permission', 'message', 'outlined', 'label', 'color', 'disabled', 'xSmall', 'block', 'large','editText','loading'],
        data: () => ({
            dialog: false,
            hasPermission: false
        }),
        mounted() {

            if (this.permission)
                this.hasPermission = this.$auth.can(this.permission);

        }
    }
</script>