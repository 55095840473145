import { setBatch } from "../library/helpers";

export default {
    SIDEBAR_MV: (state, payload) => {
        state.sidebarMV = !state.sidebarMV;
    },
    USER: (state, payload) => {
        state.user = payload
    },
    BATCH: (state, payload) => {
        window.localStorage.setItem('batch', JSON.stringify(payload));
        setBatch(payload);
        state.batch = payload
    },
    FISCAL_YEAR: (state, payload) => {
        window.localStorage.setItem('fiscalyear', JSON.stringify(payload));
        state.fiscalyear = payload
    },
    STUDENT: (state, payload) => {
        state.student.data = payload;
        state.student.guardian = [];
        state.student.guardian.push(payload.guardian);
        state.student.selectedGuardian = payload.guardian;
        state.student.selectedGuardian.family = payload.guardian.family || {};
        state.student.updateData.fname = payload.fullname.fname;
        state.student.updateData.mname = payload.fullname.mname;
        state.student.updateData.lname = payload.fullname.lname;
        state.student.updateData.email = payload.email;
        state.student.updateData.gender = payload.personal.gender.charAt(0).toUpperCase() + payload.personal.gender.slice(1);
        state.student.updateData.dob = payload.personal.dob;
        state.student.updateData.blood_group = payload.personal.blood_group;
        state.student.updateData.religion = payload.personal.religion;
        state.student.updateData.ethnicity = payload.personal.ethnicity;
        state.student.updateData.residence = payload.personal.residence;
        state.student.addressData = payload.address;
        // state.student.deleteAlert = false;
        // state.student.updateGuardian = false;
        // state.student.updateGeneral = false;
    },
    SET_GENERAL_UPDATE: (state, payload) => {
        state.student.deleteAlert = false;
        state.student.updateGuardian = false;
        state.student.updateGeneral = payload;
    },
    SET_GUARDIAN_UPDATE: (state, payload) => {
        // console.log(state.student.guardian);
        state.student.deleteAlert = false;
        state.student.updateGeneral = false;
        state.student.updateGuardian = payload;
    },
    SET_DELETE_ALERT: (state, payload) => {
        state.student.updateGeneral = false;
        state.student.updateGuardian = false;
        state.student.deleteAlert = payload;
    },
    BATCHES: (state, payload) => {
        state.batches = payload;
    },
    SELECTED_STUDENT: (state, payload) => {
        state.selected_student = payload;
    }
}
